import img_0 from "./images/img_0.png"
import img_1 from "./images/img_1.png"
import img_2 from "./images/img_2.png"
import img_3 from "./images/img_3.png"
import img_4 from "./images/img_4.png"
import img_5 from "./images/img_5.png"
import img_6 from "./images/img_6.png"
import img_7 from "./images/img_7.png"
import img_8 from "./images/img_8.png"
import img_9 from "./images/img_9.png"
import img_10 from "./images/img_10.png"
import img_11 from "./images/img_11.png"
import img_12 from "./images/img_12.png"
import img_13 from "./images/img_13.png"
import img_14 from "./images/img_14.png"
import img_15 from "./images/img_15.png"
import img_16 from "./images/img_16.png"
import img_17 from "./images/img_17.png"
import img_18 from "./images/img_18.png"
import img_19 from "./images/img_19.png"
import img_20 from "./images/img_20.png"


export default {
  "v": "5.4.3",
  "fr": 200,
  "ip": 0,
  "op": 400,
  "w": 960,
  "h": 1080,
  "nm": "DroiteGaucheSEUL",
  "ddd": 0,
  "assets": [{"id": "image_0", "w": 117, "h": 496, "u": "", "p": img_0, "e": 0}, {
    "id": "image_1",
    "w": 455,
    "h": 1678,
    "u": "",
    "p": img_1,
    "e": 0
  }, {"id": "image_2", "w": 203, "h": 721, "u": "", "p": img_2, "e": 0}, {
    "id": "image_3",
    "w": 239,
    "h": 1339,
    "u": "",
    "p": img_3,
    "e": 0
  }, {"id": "image_4", "w": 404, "h": 1231, "u": "", "p": img_4, "e": 0}, {
    "id": "image_5",
    "w": 430,
    "h": 2040,
    "u": "",
    "p": img_5,
    "e": 0
  }, {"id": "image_6", "w": 238, "h": 719, "u": "", "p": img_6, "e": 0}, {
    "id": "image_7",
    "w": 192,
    "h": 807,
    "u": "",
    "p": img_7,
    "e": 0
  }, {"id": "image_8", "w": 336, "h": 1316, "u": "", "p": img_8, "e": 0}, {
    "id": "image_9",
    "w": 667,
    "h": 2459,
    "u": "",
    "p": img_9,
    "e": 0
  }, {"id": "image_10", "w": 2519, "h": 1243, "u": "", "p": img_10, "e": 0}, {
    "id": "image_11",
    "w": 207,
    "h": 1159,
    "u": "",
    "p": img_11,
    "e": 0
  }, {"id": "image_12", "w": 260, "h": 1234, "u": "", "p": img_12, "e": 0}, {
    "id": "image_13",
    "w": 194,
    "h": 718,
    "u": "",
    "p": img_13,
    "e": 0
  }, {"id": "image_14", "w": 267, "h": 863, "u": "", "p": img_14, "e": 0}, {
    "id": "image_15",
    "w": 236,
    "h": 720,
    "u": "",
    "p": img_15,
    "e": 0
  }, {"id": "image_16", "w": 404, "h": 1231, "u": "", "p": img_16, "e": 0}, {
    "id": "image_17",
    "w": 240,
    "h": 1007,
    "u": "",
    "p": img_17,
    "e": 0
  }, {"id": "image_18", "w": 349, "h": 1064, "u": "", "p": img_18, "e": 0}, {
    "id": "image_19",
    "w": 288,
    "h": 870,
    "u": "",
    "p": img_19,
    "e": 0
  }, {"id": "image_20", "w": 349, "h": 1064, "u": "", "p": img_20, "e": 0}, {
    "id": "comp_0",
    "layers": [{
      "ddd": 0,
      "ind": 2,
      "ty": 2,
      "nm": "Objet dynamique vectoriel 4",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [1862, 1359, 0],
            "e": [1862, 1194, 0],
            "to": [0, -27.5, 0],
            "ti": [0, 27.5, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [58.5, 248, 0], "ix": 1},
        "s": {"a": 0, "k": [110, 110, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 112,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 3,
      "ty": 2,
      "nm": "Objet dynamique vectoriel 3",
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [1609.5, 1804, 0],
            "e": [1609.5, 1290, 0],
            "to": [0, -85.6666641235352, 0],
            "ti": [0, 85.6666641235352, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [227.5, 839, 0], "ix": 1},
        "s": {"a": 0, "k": [86, 86, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 32,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 4,
      "ty": 2,
      "nm": "Objet dynamique vectoriel copie 3",
      "refId": "image_2",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [1046.5, 1449.719, 0],
            "e": [1046.5, 1081.719, 0],
            "to": [0, -61.3333320617676, 0],
            "ti": [0, 61.3333320617676, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [101.5, 360.5, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 16,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 5,
      "ty": 2,
      "nm": "Objet dynamique vectoriel copie 16",
      "refId": "image_3",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [1193.5, 1768, 0],
            "e": [1193.5, 1298, 0],
            "to": [0, -78.3333358764648, 0],
            "ti": [0, 78.3333358764648, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [119.5, 669.5, 0], "ix": 1},
        "s": {"a": 0, "k": [74, 74, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 304,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 6,
      "ty": 2,
      "nm": "Objet dynamique vectoriel copie 15",
      "refId": "image_4",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [1359, 1713.5, 0],
            "e": [1359, 1413.5, 0],
            "to": [0, -50, 0],
            "ti": [0, 50, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [202, 615.5, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 232,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 7,
      "ty": 2,
      "nm": "Objet dynamique vectoriel 2",
      "refId": "image_5",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [1390, 2225, 0],
            "e": [1390, 1843, 0],
            "to": [0, -63.6666679382324, 0],
            "ti": [0, 63.6666679382324, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [215, 1020, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 56,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 8,
      "ty": 2,
      "nm": "Objet dynamique vectoriel copie 4",
      "refId": "image_6",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [831, 1467.5, 0],
            "e": [831, 1155.5, 0],
            "to": [0, -52, 0],
            "ti": [0, 52, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [119, 359.5, 0], "ix": 1},
        "s": {"a": 0, "k": [107, 107, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 32,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 9,
      "ty": 2,
      "nm": "Objet dynamique vectoriel",
      "refId": "image_7",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [619, 1494, 0],
            "e": [619, 1140, 0],
            "to": [0, -59, 0],
            "ti": [0, 59, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [96, 403.5, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 48,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 10,
      "ty": 2,
      "nm": "Objet dynamique vectoriel",
      "refId": "image_8",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [377, 2101, 0],
            "e": [377, 1397, 0],
            "to": [0, -128.647857666016, 0],
            "ti": [0, 0.01880178414285, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [168, 658, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 24,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 11,
      "ty": 2,
      "nm": "Objet dynamique vectoriel copie 5",
      "refId": "image_9",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [80.885, 1978, 0],
            "e": [80.885, 1214, 0],
            "to": [0, -127.333335876465, 0],
            "ti": [0, 127.333335876465, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [333.5, 1229.5, 0], "ix": 1},
        "s": {"a": 0, "k": [72, 72, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 0,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 12,
      "ty": 4,
      "nm": "Shape Layer 1",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {"a": 0, "k": [960, 389, 0], "ix": 2},
        "a": {"a": 0, "k": [0, -542, 0], "ix": 1},
        "s": {"a": 0, "k": [75, 50, 100], "ix": 6}
      },
      "ao": 0,
      "shapes": [{
        "ty": "gr",
        "it": [{
          "ty": "rc",
          "d": 1,
          "s": {"a": 0, "k": [1923.992, 1084], "ix": 2},
          "p": {"a": 0, "k": [0, 0], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 4},
          "nm": "Rectangle Path 1",
          "mn": "ADBE Vector Shape - Rect",
          "hd": false
        }, {
          "ty": "st",
          "c": {"a": 0, "k": [0, 0, 0, 1], "ix": 3},
          "o": {"a": 0, "k": 100, "ix": 4},
          "w": {"a": 0, "k": 0, "ix": 5},
          "lc": 1,
          "lj": 1,
          "ml": 4,
          "ml2": {"a": 0, "k": 4, "ix": 8},
          "bm": 0,
          "nm": "Stroke 1",
          "mn": "ADBE Vector Graphic - Stroke",
          "hd": false
        }, {
          "ty": "fl",
          "c": {"a": 0, "k": [1, 1, 1, 1], "ix": 4},
          "o": {"a": 0, "k": 100, "ix": 5},
          "r": 1,
          "bm": 0,
          "nm": "Fill 1",
          "mn": "ADBE Vector Graphic - Fill",
          "hd": false
        }, {
          "ty": "tr",
          "p": {"a": 0, "k": [-0.004, 0], "ix": 2},
          "a": {"a": 0, "k": [0, 0], "ix": 1},
          "s": {"a": 0, "k": [100, 158.471], "ix": 3},
          "r": {"a": 0, "k": 0, "ix": 6},
          "o": {"a": 0, "k": 100, "ix": 7},
          "sk": {"a": 0, "k": 0, "ix": 4},
          "sa": {"a": 0, "k": 0, "ix": 5},
          "nm": "Transform"
        }],
        "nm": "Rectangle 1",
        "np": 3,
        "cix": 2,
        "bm": 0,
        "ix": 1,
        "mn": "ADBE Vector Group",
        "hd": false
      }],
      "ip": 0,
      "op": 3032,
      "st": 0,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 13,
      "ty": 2,
      "nm": "ROUTE 2",
      "refId": "image_10",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [963.033, 1698.5, 0],
            "e": [963.033, 964.5, 0],
            "to": [0, -122.333335876465, 0],
            "ti": [0, 122.333335876465, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [1259.5, 621.5, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "hasMask": true,
      "masksProperties": [{
        "inv": false,
        "mode": "a",
        "pt": {
          "a": 0,
          "k": {
            "i": [[0, 0], [0, 0], [0, 0], [0, 0]],
            "o": [[0, 0], [0, 0], [0, 0], [0, 0]],
            "v": [[1256.467, -69], [240.467, -69], [240.467, 1313.172], [1256.467, 1313.172]],
            "c": true
          },
          "ix": 1
        },
        "o": {"a": 0, "k": 100, "ix": 3},
        "x": {"a": 0, "k": 0, "ix": 4},
        "nm": "Mask 1"
      }],
      "ip": 0,
      "op": 3032,
      "st": 16,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 14,
      "ty": 2,
      "nm": "Objet dynamique vectoriel",
      "refId": "image_11",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [726.5, 1679.5, 0],
            "e": [726.5, 640.5, 0],
            "to": [0, -173.16667175293, 0],
            "ti": [0, 173.16667175293, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [103.5, 579.5, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 120,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 15,
      "ty": 2,
      "nm": "Objet dynamique vectoriel",
      "refId": "image_12",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [562, 1717, 0],
            "e": [562, 540, 0],
            "to": [0, -196.16667175293, 0],
            "ti": [0, 196.16667175293, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [130, 617, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 120,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 16,
      "ty": 2,
      "nm": "Objet dynamique vectoriel",
      "refId": "image_13",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [364, 1458, 0],
            "e": [364, 249, 0],
            "to": [0, -201.5, 0],
            "ti": [0, 201.5, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [97, 359, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 136,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 17,
      "ty": 2,
      "nm": "Objet dynamique vectoriel copie 7",
      "refId": "image_14",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [496.5, 1523.5, 0],
            "e": [496.5, 357.5, 0],
            "to": [0, -194.33332824707, 0],
            "ti": [0, 194.33332824707, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [133.5, 431.5, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 216,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 18,
      "ty": 2,
      "nm": "Objet dynamique vectoriel copie 12",
      "refId": "image_15",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [149, 1453, 0],
            "e": [149, 500, 0],
            "to": [0, -158.83332824707, 0],
            "ti": [0, 158.83332824707, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [118, 360, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 224,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 19,
      "ty": 2,
      "nm": "Objet dynamique vectoriel copie 11",
      "refId": "image_16",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [202, 1706.5, 0],
            "e": [202, 135.5, 0],
            "to": [0, -261.833343505859, 0],
            "ti": [0, 261.833343505859, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [202, 615.5, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 232,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 20,
      "ty": 2,
      "nm": "Objet dynamique vectoriel copie 13",
      "refId": "image_17",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [1321, 1589.5, 0],
            "e": [1321, 433.5, 0],
            "to": [0, -192.66667175293, 0],
            "ti": [0, 192.66667175293, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [120, 503.5, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 224,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 21,
      "ty": 2,
      "nm": "Objet dynamique vectoriel copie 14",
      "refId": "image_4",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [1919, 1705.5, 0],
            "e": [1919, 933.5, 0],
            "to": [0, -128.66667175293, 0],
            "ti": [0, 128.66667175293, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [202, 615.5, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 232,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 22,
      "ty": 2,
      "nm": "Objet dynamique vectoriel copie 2",
      "refId": "image_4",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [1765, 1713.5, 0],
            "e": [1765, 293.5, 0],
            "to": [0, -236.66667175293, 0],
            "ti": [0, 236.66667175293, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [202, 615.5, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 232,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 23,
      "ty": 2,
      "nm": "Objet dynamique vectoriel copie",
      "refId": "image_18",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [1217.5, 1619, 0],
            "e": [1217.5, 390, 0],
            "to": [0, -204.83332824707, 0],
            "ti": [0, 204.83332824707, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [174.5, 532, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 240,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 24,
      "ty": 2,
      "nm": "Objet dynamique vectoriel 5",
      "refId": "image_19",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [1474, 1236, 0],
            "e": [1474, 270, 0],
            "to": [0, -161, 0],
            "ti": [0, 161, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [144, 435, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 104,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 25,
      "ty": 2,
      "nm": "Objet dynamique vectoriel copie 10",
      "refId": "image_3",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_1_0p333_0",
            "t": 0,
            "s": [1049.5, 1768, 0],
            "e": [1049.5, 377, 0],
            "to": [0, -231.83332824707, 0],
            "ti": [0, 231.83332824707, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [119.5, 669.5, 0], "ix": 1},
        "s": {"a": 0, "k": [74, 74, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 304,
      "bm": 0
    }, {
      "ddd": 0,
      "ind": 26,
      "ty": 2,
      "nm": "Objet dynamique vectoriel copie 6",
      "refId": "image_20",
      "sr": 1,
      "ks": {
        "o": {"a": 0, "k": 100, "ix": 11},
        "r": {"a": 0, "k": 0, "ix": 10},
        "p": {
          "a": 1,
          "k": [{
            "i": {"x": 0.667, "y": 0.99},
            "o": {"x": 0.333, "y": 0},
            "n": "0p667_0p99_0p333_0",
            "t": 0,
            "s": [787.5, 1614, 0],
            "e": [785.5, 257.099, 0],
            "to": [0, -226.13362121582, 0],
            "ti": [0, 226.331848144531, 0]
          }, {
            "i": {"x": 0.667, "y": 1},
            "o": {"x": 0.333, "y": 0.034},
            "n": "0p667_1_0p333_0p034",
            "t": 398,
            "s": [785.5, 257.099, 0],
            "e": [787.5, 257, 0],
            "to": [0, -0.03307822346687, 0],
            "ti": [0, 0.03304925188422, 0]
          }, {"t": 400}],
          "ix": 2
        },
        "a": {"a": 0, "k": [174.5, 532, 0], "ix": 1},
        "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
      },
      "ao": 0,
      "ip": 0,
      "op": 3032,
      "st": 272,
      "bm": 0
    }]
  }],
  "layers": [{
    "ddd": 0,
    "ind": 2,
    "ty": 0,
    "nm": "Gauche 2",
    "refId": "comp_0",
    "sr": 1,
    "ks": {
      "o": {"a": 0, "k": 100, "ix": 11},
      "r": {"a": 0, "k": 0, "ix": 10},
      "p": {"a": 0, "k": [960, 540, 0], "ix": 2},
      "a": {"a": 0, "k": [960, 540, 0], "ix": 1},
      "s": {"a": 0, "k": [100, 100, 100], "ix": 6}
    },
    "ao": 0,
    "w": 1920,
    "h": 1080,
    "ip": 0,
    "op": 3032,
    "st": 0,
    "bm": 0
  }],
  "markers": []
}