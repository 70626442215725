export default {
  SET_APP_LOADED: 'SET_APP_LOADED',
  SET_LANG: 'SET_LANG',
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  SET_CURRENT_SCENE: 'SET_CURRENT_SCENE',
  SET_SPLIT_SCREEN: 'SET_SPLIT_SCREEN',
  SET_USER_CURRENT_SCENE: 'SET_USER_CURRENT_SCENE',
  SET_USER_INDICATION_ACTIVE: 'SET_USER_INDICATION_ACTIVE',
  SET_USER_INDICATION_OPEN: 'SET_USER_INDICATION_OPEN',
  SET_USER_INDICATION_TITLE: 'SET_USER_INDICATION_TITLE',
  SET_USER_INDICATION_DESCRIPTION: 'SET_USER_INDICATION_DESCRIPTION',
  SET_USER_INDICATION_THEME: 'SET_USER_INDICATION_THEME',
  SET_USER_STAIRS_PROGRESSION: 'SET_USER_STAIRS_PROGRESSION',
  SET_PLAYER1_SPLIT_SCREEN_PERCENTAGE: 'SET_PLAYER1_SPLIT_SCREEN_PERCENTAGE'
};
