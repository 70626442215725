/**
 * Behaves the same as setTimeout except uses requestAnimationFrame() where possible for better performance
 * @param {function} fn The callback function
 * @param {int} delay The delay in milliseconds
 */

export const requestTimeout = (fn, delay) => {
	if( !window.requestAnimationFrame      	&& 
	    !window.webkitRequestAnimationFrame && 
	    !(window.mozRequestAnimationFrame && window.mozCancelRequestAnimationFrame) && // Firefox 5 ships without cancel support
	    !window.oRequestAnimationFrame      && 
	    !window.msRequestAnimationFrame )
		return window.setTimeout(fn, delay)
			
	var start = new Date().getTime(),
		handle = new Object()
		
	function loop(){
		var current = new Date().getTime(),
			delta = current - start
			
		delta >= delay ? fn.call() : handle.value = window.requestAnimationFrame(loop);
	};
	
	handle.value = window.requestAnimationFrame(loop) 
	return handle
};

/**
 * Behaves the same as clearTimeout except uses cancelRequestAnimationFrame() where possible for better performance
 * @param {int|object} fn The callback function
 */
export const clearRequestTimeout = (handle) =>{
    window.cancelAnimationFrame ? window.cancelAnimationFrame(handle.value) :
    window.webkitCancelAnimationFrame ? window.webkitCancelAnimationFrame(handle.value) :
    window.webkitCancelRequestAnimationFrame ? window.webkitCancelRequestAnimationFrame(handle.value) : /* Support for legacy API */
    window.mozCancelRequestAnimationFrame ? window.mozCancelRequestAnimationFrame(handle.value) :
    window.oCancelRequestAnimationFrame	? window.oCancelRequestAnimationFrame(handle.value) :
    window.msCancelRequestAnimationFrame ? window.msCancelRequestAnimationFrame(handle.value) :
    clearTimeout(handle);
};