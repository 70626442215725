/** @jsx jsx **/
import React from 'react';
import './Loading.scss';
import { jsx, css } from '@emotion/core'
import { TweenMax } from 'gsap';
const logo = (
  <>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 436 78.43">
      <path d="M0,11.78H21q10.22,0,15.41,4.11t5.19,12.19a16.36,16.36,0,0,1-2,8.58,10.84,10.84,0,0,1-6,4.72,10.62,10.62,0,0,1,7.55,4.29q2.58,3.6,2.57,9.87,0,8.07-5.19,12.18t-15.4,4.12H0Zm21,26.6q6.78,0,10.26-2.62t3.47-7.68a7.94,7.94,0,0,0-3.47-6.95Q27.8,18.73,21,18.73H6.86V38.38ZM23.17,65Q30,65,33.42,62.49A8.4,8.4,0,0,0,36.9,55.2a8.51,8.51,0,0,0-3.48-7.34Q30,45.33,23.17,45.33H6.86V65Z" />
      <path d="M53.2,11.86h6.87v53.2H89.24v6.87h-36Z" />
      <path d="M95.25,29q0-8.84,5.62-13.34t16.52-4.51q10.89,0,16.52,4.51T139.53,29V71.93h-6.87V47H102.11V71.93H95.25Zm37.41,11.16V29.37q0-5.58-3.86-8.46T117.39,18q-7.56,0-11.41,2.87t-3.87,8.46V40.18Z" />
      <path d="M149.82,58.2V25.68l11.59-13.82H188l10,12-4.46,5.41-8.93-10.55h-20l-8,9.52v27L165,65.06h20.51l8.93-10.64,4.46,5.5-10,12H161.41Z" />
      <path d="M208.35,11.86h6.86V71.93h-6.86ZM242,58.2,231.17,45.33H210.41V38.46h20.76l8.24-9.69V11.86h6.86V31.51l-8.49,10v.69l11.07,13.21V71.93H242Z" />
      <path d="M286.48,64.81Q279.75,57,279.74,41.9T286.48,19q6.74-7.8,19.69-7.81Q319.3,11.17,326,19T332.6,41.9Q332.6,57,326,64.81t-19.78,7.81Q293.21,72.62,286.48,64.81Zm34.36-5.11q4.89-6,4.9-17.8t-4.9-17.81Q316,18,306.17,18q-9.62,0-14.59,6.05t-5,17.81q0,11.74,5,17.8t14.59,6Q316,65.75,320.84,59.7Z" />
      <path d="M341.18,58.8V11.86h6.87V56.05l7.55,9.1h17.07l7.55-9.1V11.86h6.87V58.8L376,71.93H352.25Z" />
      <path d="M410.69,18.73H392.24V11.86H436v6.87H417.55v53.2h-6.86Z" />
      <rect className="cls-1" x="260.65" y="0.6" width="8.04" height="77.23" />
    </svg>
  </>
);

const logoClip = (
  <>
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 436 78.43">
      <path d="M0,11.78H21q10.22,0,15.41,4.11t5.19,12.19a16.36,16.36,0,0,1-2,8.58,10.84,10.84,0,0,1-6,4.72,10.62,10.62,0,0,1,7.55,4.29q2.58,3.6,2.57,9.87,0,8.07-5.19,12.18t-15.4,4.12H0Zm21,26.6q6.78,0,10.26-2.62t3.47-7.68a7.94,7.94,0,0,0-3.47-6.95Q27.8,18.73,21,18.73H6.86V38.38ZM23.17,65Q30,65,33.42,62.49A8.4,8.4,0,0,0,36.9,55.2a8.51,8.51,0,0,0-3.48-7.34Q30,45.33,23.17,45.33H6.86V65Z" />
      <path d="M53.2,11.86h6.87v53.2H89.24v6.87h-36Z" />
      <path d="M95.25,29q0-8.84,5.62-13.34t16.52-4.51q10.89,0,16.52,4.51T139.53,29V71.93h-6.87V47H102.11V71.93H95.25Zm37.41,11.16V29.37q0-5.58-3.86-8.46T117.39,18q-7.56,0-11.41,2.87t-3.87,8.46V40.18Z" />
      <path d="M149.82,58.2V25.68l11.59-13.82H188l10,12-4.46,5.41-8.93-10.55h-20l-8,9.52v27L165,65.06h20.51l8.93-10.64,4.46,5.5-10,12H161.41Z" />
      <path d="M208.35,11.86h6.86V71.93h-6.86ZM242,58.2,231.17,45.33H210.41V38.46h20.76l8.24-9.69V11.86h6.86V31.51l-8.49,10v.69l11.07,13.21V71.93H242Z" />
      <path d="M286.48,64.81Q279.75,57,279.74,41.9T286.48,19q6.74-7.8,19.69-7.81Q319.3,11.17,326,19T332.6,41.9Q332.6,57,326,64.81t-19.78,7.81Q293.21,72.62,286.48,64.81Zm34.36-5.11q4.89-6,4.9-17.8t-4.9-17.81Q316,18,306.17,18q-9.62,0-14.59,6.05t-5,17.81q0,11.74,5,17.8t14.59,6Q316,65.75,320.84,59.7Z" />
      <path d="M341.18,58.8V11.86h6.87V56.05l7.55,9.1h17.07l7.55-9.1V11.86h6.87V58.8L376,71.93H352.25Z" />
      <path d="M410.69,18.73H392.24V11.86H436v6.87H417.55v53.2h-6.86Z" />
      <rect x="260.65" y="0.6" width="8.04" height="77.23" />
    </svg>
  </>
)

export const Loading = props => {
  return (
    <div className="loading">
      <div className="loading__wrapper" >
      <div className="loading__wrapper__blackout">
      {logo}
      <span className="clip" css={svgclass(props.progress)}>
        {logoClip}
      </span>
      </div>
     
        <p className="loading__wrapper__sentence">loading</p>
      </div>
      <span className="loading__wrapper__logo" >
        <svg className="test" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.94 56.58">
          <g id="Calque_2" data-name="Calque 2">
            <g id="Calque_1-2" data-name="Calque 1">
              <path d="M31,14.5A14.52,14.52,0,1,1,16.54,0,14.51,14.51,0,0,1,31,14.5Zm-11.26,0a3.25,3.25,0,0,0-3.27-3.13,3.16,3.16,0,0,0-3.18,3.36,3.22,3.22,0,0,0,3.32,3.07A3.32,3.32,0,0,0,19.72,14.5Z" />
              <path d="M29.29,56.58c1.62-2.76,1-4.25-1.76-5.12-6.24-2-12.51-3.79-18.72-5.83A17.45,17.45,0,0,1,3.56,43a9.09,9.09,0,0,1-.49-14c0,.7-.32,1.81.09,2.48a4.51,4.51,0,0,0,2.41,1.66c6.16,2,12.38,3.8,18.54,5.79A18.38,18.38,0,0,1,29,41.16C34.07,44.76,34.34,52.21,29.29,56.58Z" />
            </g>
          </g>
        </svg>
      </span>
    </div>
  );
};

const svgclass = progress => css`
  clip-path: inset(0 ${100 - progress * 100}% 0 0);
`;
